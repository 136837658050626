// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1279px) {
    :root {
        --rowGap: 25px;
        --fontSize: 13px;
        --lineHeight: 1.4;
    }

    .detail-omnibus {
        align-self: flex-start;
        margin-top: 0;
        font-size: 11px;
    }

    .footer-bottom-bar {
        padding: 30px 0 100px;
    }

    #container {
        overflow: hidden;
    }

    .detail-omnibus {
        text-align: left;
    }

    main {
        padding-bottom: var(--rowGap) !important;
    }

    main.aside {
        grid-template-columns: minmax(0, 1fr);
        grid-gap: var(--rowGap) 0;
    }

    .inner {
        width: 100%;
        padding: 0 10px;
    }

    * {
        -webkit-tap-highlight-color: rgba(189, 195, 199, .75);
        -webkit-text-size-adjust: none;
    }

    body.privacy-box-visible {
        overflow: hidden;
        
        #container {
            filter: blur(5px);
            pointer-events: none;
        }
    }

    body:has(.left-side-cookies) {

        .privacy-box-trigger {
            bottom: 15px;
            width: 35px;
            height: 35px;

            &.right-side-cookies {
                right: 15px;
            }

            &.left-side-cookies {
                left: 15px;
            }
        }
    }

    .privacy-box {
        bottom: auto;
        right: auto;
        left: 15px !important;
        right: 15px !important;
        top: 50%;
        transform: translateY(-50%);
        max-width: 100vw;
        max-height: ~"calc(100% - 40px)";
    }

    input[type=text],
    input[type=password],
    input[type=email],
    select,
    textarea {
        font-size: 16px;
        padding: 0 14px;
    }

    textarea {
        padding: 10px 14px;
    }

    #facebook-box,
    .detail-loupe {
        display: none;
    }

    .headline {
        font-size: 20px !important;
        margin-bottom: 10px;
    }

    #content {
        width: 100%;
        clear: both;
    }

    .sidebar {
        width: auto;
        display: contents;

        .sidebar-tabs {
            display: none;
        }
    }

    // SEARCH ENGINE
    #search-engine {
        display: none;
    }

    .newsletter-bg {
        background-image: none !important;
    }

    .newsletter-input {
        width: 100%;
    }

    .footer-content {
        flex-direction: column;
        padding: 20px 0;
    }

    .footer-item {
        width: 100% !important;
    }

    body:has(.gs-filtr-container) .filter-trigger {
        display: flex;
    }

    .filter-trigger,
    .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button {
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        min-width: 0;
        font-size: 13px;

        &:before {
            display: none;
        }

        svg {
            position: static;
            transform: rotate(90deg);
            height: auto;
            width: 18px;
            filter: brightness(0);
            margin-right: 5px;
        }
    }

    .filter-trigger:after {
        content: 'i sortowanie';
    }

    // SORTER 
    .dropdown-picker[data-dropdown-type="sort"] {
        display: none;
    }

    // FILTER
    #filter {
        display: none;
    }

    .product-images-mini {
        width: 100%;
        height: auto;
        margin-top: 10px;

        .swiper-wrapper {
            transform: none !important;
            width: auto;
            justify-content: center;
        }

        .swiper-slide {
            width: 10px;
            height: 10px;
            background-color: @border-color;
            border-radius: 50%;
            margin: 0 5px;
        }

        img {
            display: none !important;
        }
    }

    .user-custom-content p {
        margin-bottom: 10px;
    }

    // OTHER
    hr {
        margin: 20px 0;
    }

    .overlay-visible {
        overflow: hidden;
        height: 100dvh;
        width: 100dvw;
        position: fixed;
        top: 0;
    }

    main:not(.aside) #tree {
        display: block;
    }

    .tree-canvas {
        position: fixed;
        background: rgb(0, 0, 0) none repeat scroll 0% 0%;
        will-change: opacity;
        transition: opacity 300ms ease 0s;
        inset: 0px;
        z-index: 1001;
        pointer-events: none;
        opacity: 0;
    }

    #tree {
        position: fixed;
        top: 0;
        left: 0;
        transform: translate3d(-100%, 0, 0);
        overflow: hidden;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    :root {
        --rowGap: 25px;
        --prodGap: 20px;
        --prodGapMainpage: var(--prodGap);
        --prodGapVertical: 30px;
        --iconSize: 20px;
    }


    //BASE
    .top-bar {
        padding: 4px 0;
        font-size: 13px;

        .top-bar-close {
            margin-right: 4px;

            &:before {
                font-size: 17px;
            }
        }
    }

    #container {
        overflow: clip;
    }

    .shop-header {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        background-color: @total-color;
        .shadow();
    }

    .tree-trigger,
    .search-engine-trigger {
        display: block;
    }

    .shop-header-container {
        gap: 0 30px;
        grid-template-columns: auto auto repeat(2, minmax(0, 1fr));
        align-items: center;
        grid-row-gap: 8px;
        padding: 6px 0 8px;
    }

    .logo {
        grid-row: 1 / 3;
        width: 100px;
    }

    #tree-top {
        display: none;
    }

    .header-top {
        display: contents;
    }

    .mainmenu {
        grid-column: 2 / 4;
        grid-row: 1 / 2;
        margin: 0;
        align-self: end;
    }

    .header-contact-trigger {
        grid-column: 4 / 5;
        grid-row: 1 / 2;
        align-self: end;
    }

    .dark-mode-toggle {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
    }

    .header-shipment {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
    }

    .header-bottom {
        grid-column: 4 / 5;
        grid-row: 2 / 3;
        justify-self: end;
    }

    .header-tools-wrapper {
        gap: 20px;
    }

    .search-engine-trigger,
    #wishlist-box,
    .log-button,
    #cart-box,
    .tree-trigger {
        padding-top: 27px;

        &:before {
            font-size: 24px;
        }

        em {
            font-size: 10px;
            font-weight: bold;
        }
    }

    .tree-trigger {
        

        .hamburger-icon {
            gap: 6px;
            height: 24px;

            span {
                height: 1px;
            }
        }
    }

    // MAINPAGE
    body[data-type="mainpage"] main.inner {
        padding-top: var(--rowGap);
    }

    .icon-category-section {
        padding-bottom: var(--rowGap);
        gap: 20px 12px;
    }

    .icon-category-dropdown {
        gap: 20px;
    }

    a.icon-category-trigger {
        gap: 10px;

        span {
            padding: 0 6px;
            font-size: 12px;
        }

        &.dropdownActive {

            &:after {
                bottom: -38px;
            }
        }
    }

    .mainpage-greeting-wrapper:has(> :nth-child(2)) figure {
        top: 132px;
    }

    .mainpage-greeting-wrapper .mainpage-greeting {
        padding: var(--rowGap) 0;
    }

    .greeting-img:only-child {
        max-width: 944px;
        width: 100% !important;
        padding: 0 !important;

        img {
            width: 100% !important;
        }
    }

    .blog-banners-section {
        margin-top: 0;

        i {
            font-size: 12px;
            gap: 8px;
        }
    }

    // FOOTER
    .promo-bar {
        height: 170px;
    }

    .promo-social-links {

        i {
            margin-right: 6px;
            font-size: 20px;
        }

        a:before {
            font-size: 24px;
        }
    }

    .promo-bar-logo {
        width: 278px;
        margin-left: -60px;
        margin-right: 0;
    }

    .promo-banners-wrapper {
        justify-content: space-around;
    }

    #newsletter {
        padding: 32px 0;
    }

    .newsletter-header b {
        font-size: 20px;
    }

    .newsletter-input {
        max-width: 80%;
        --iconSize: 24px;
    }

    .footer-item.submenu ul {
        columns: 1;
    }

    .footer-item > strong {
        font-size: 18px;
    }

    .footer-item.contact .contact-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .footer-logo {
        margin-top: 10px;
        width: 144px !important;
    }

    .footer-bottom-bar {
        flex-direction: column;
        gap: 16px;
        padding: 16px 0 67px;
    }

    .footer-item.contact {
        max-width: none;
    }

    // LISTING
    .sidebar {
        display: contents;

        > *:not(#tree, .tree-canvas) {
            display: none;
        }
    }

    #breadcrumb {
        padding: 10px 0;
        margin-bottom: -10px;
    }

    .listing-wrapper {
        justify-content: flex-start;
        gap: var(--rowGap) 18px;

        .headline {
            margin-bottom: 0;
            align-self: center;
        }

        &:has(.category-headline) {
            margin-top: 0;
        }
    }

    .pagination {
        margin-top: 0;
        gap: 24px;
    }

    .category-description {
        margin-top: 18px;
    }


    .category-headline {
        width: 100%;
    }

    .navigation-wrapper {
        margin-left: 0;
    }

    #pills-container:not(:empty) {
        margin-top: -12px;
        margin-bottom: 0 !important;
    }

    .product {
        gap: 10px;

        &:after {
            display: none !important;
        }
    }

    .product-bottom-wrapper {
        flex-wrap: wrap;
        gap: 10px 4px;
    }

    .product-price {
        width: 100%;
        flex-direction: row;
        justify-content: center;
        gap: 8px;
        align-items: flex-end;
        height: auto;

        b {
            font-size: 14px;
        }

        del {
            font-size: 13px;
        }
    }

    .product-wishlist {
        margin-left: 0;
    }

    .product .product-add {
        flex: 1;
        font-size: 14px;
        padding: 0;

        &:after {
            content: 'produkt';
            text-transform: uppercase;
            font-weight: 700;
            margin-left: -6px;
        }
    }

    .product:has(.product-add.disabled) {

        .product-wishlist {
            flex: 1;
            display: flex;
            gap: 14px;

            &:after {
                content: 'Dodaj do ulubionych';
                text-transform: uppercase;
                font-weight: 700;
                margin-left: -6px;
                font-size: 14px; 
            }
        }
    }

    // DETAIL
    .detail-main-wrapper {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;

        .detail-headline {
            margin: 0;
            padding: 0;
            text-align: center;
        }
    }

    #detail-images {
        align-items: center;
        flex-direction: column;
        gap: 20px;
        height: auto;
        left: auto;
        margin: 0 auto;
        position: relative;
        top: auto;
        width: 100%;

        .swiper-button-prev {
            left: 10px;
        }

        .swiper-button-next {
            right: 10px;
        }
    }

    .product-images-mini {
        height: auto;
        margin-top: 10px;
        width: 100%;

        .size-custom {
            padding: 0 !important;
        }
    }

    .detail-info-wrapper {
        margin-top: 0;
        padding: 16px;
    }

    .detail-badges {
        padding-bottom: 10px;
        justify-content: flex-start;
    }

    .detail-pricing {
        margin-bottom: 8px;
        justify-content: flex-start;
    }

    .detail-adding {
        margin-top: 4px;
        margin-bottom: 20px;
    }

    .detail-options dl {
        padding-bottom: 16px;

        dt {
            font-size: 14px;
        }
    }

    .detail-accordion-trigger {
        font-size: 16px;
    }

    .side-xsell-wrapper {
        display: none;
    }

    .detail-accordion {
        padding: 20px 0 16px;
    }

    #detail-info:has(.detail-accordion) .detail-shipping-cost {
        margin-top: 16px;
    }

    .detail-additional-infos {
        margin-left: 0;
    }

    .detail-stars-wrapper {
        padding: 0 !important;
        margin: 0 !important;
    }

    .detail-additional-infos > * {
        padding-top: 16px;
        margin-top: 16px;
    }

    .detail-buttons {
        font-size: 14px;

        > * {
            padding-left: 32px;
        }
    }

    .detail-main-info {
        margin-top: 5px;
        gap: var(--rowGap);
    }

    #detail-traits .trait {
        gap: 24px;

        b {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 50%;
            flex: 1;
        }

        .trait-desc {
            width: 50%;
            flex: 1;
        }
    }

    .detail-main-info .headline {
        margin-bottom: 10px;
    }

    #detail-traits h2{
        padding-bottom: 10px;
    }

    .detail-comments-header {
        margin-bottom: 10px;

        .headline {
            margin-bottom: 0;
        }
    }

    .bought-with-wrapper .headline {
        margin-bottom: 18px;
    }

    .comment > cite {
        padding: 20px;
        margin-bottom: 12px;
    }

    .comment-response {
        margin-block: 12px 6px;
        padding: 20px;
    }

    .under-comment .added-date {
        padding-left: 17px;
        margin-left: 0;
    }
    // iOS 12 fixes
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        .header-tools-wrapper {

            > * {
                margin-left: 10px;
            }
        }

        .hamburger-icon {
            .grid();
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, 6px);
            gap: 0;
            justify-items: center;
            align-items: center;
        }

        .tree-canvas {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}