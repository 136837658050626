// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1100px) {

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    .shop-header-container {
        gap: 8px 16px;
    }

    .mainmenu {
        gap: 16px;

        a {
            font-size: 14px;
        }
    }

    @media (max-width: 910px) {

        .header-shipment,
        .mainmenu {
            display: none;
        }

        .shop-header-container {
            grid-template-columns: auto auto minmax(0, 1fr);
        }

        .header-contact-trigger {
            grid-column: 3 / 4;
        }

        .dark-mode-toggle {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
            align-self: end;
        }

        .header-bottom {
            grid-column: 2 / 4;
        }
    }

    :root {
        --categorySectionColumns: 6;
    }

    @media (max-width: 870px) {
        :root {
            --categorySectionColumns: 4;
        }

        .icon-category-dropdown .icon-subcategory-list {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    .product {
        .product-wishlist,
        .product-add {
            font-size: 13px;
            gap: 6px;

            &:before {
                font-size: 16px;
            }
        }

        .product-add:after {
            margin-left: -2px;
        }

        &:has(.product-add.disabled) .product-wishlist {

            &:before {
                display: none;
            }

            &:after {
                margin-left: 0;
                font-size: 12px;
                white-space: nowrap;
            }
        }
    }

    .pagination {
        gap: 12px;
    }

    .mainpage-greeting-wrapper {
        flex-direction: column;
        align-items: center;

        figure {
            width: 100% !important;
            max-width: 944px !important;
            margin: 0 auto;
            padding: var(--rowGap);
            padding-bottom: 0;

            &:before,
            &:after {
                left: calc(var(--rowGap) - 2px) !important;
            }

            &:before {
                top: calc(var(--rowGap)) !important;
            }
        }

        .mainpage-greeting {
            margin-right: 0 !important;
            text-align: center;
            padding-bottom: 0;
            max-width: 90%;
        }
    }

    .blog-banners-wrapper {
        grid-template-columns: minmax(0, 1fr);
        gap: 18px;
    }

    .blog-banner {
        grid-template-rows: auto auto auto;

        figure {
            grid-row: span 4;
        }
    }

    .promo-bar {
        height: 100px;
    }

    .promo-social-links {
        gap: 8px;

        i {
            display: none;
        }

        a:before {
            font-size: 20px;
        }
    }

    .promo-bar-logo {
        width: 160px;
        margin-left: -40px;
        margin-right: 8px;
    }

    .under-comment {
        padding: 0;
        font-size: 12px;
        gap: 13px;

        .added-date {
            padding-left: 13px;
        }

        .purchase-confirmed {
            gap: 8px;

            &:before {
                font-size: 18px;
            }
        }
    }
    
    .comment-response {
        margin-left: 18px;
    }

    .promo-banners-wrapper {
        gap: 10px;

        .promo-banner {
            grid-template-columns: 30px minmax(0, 1fr);
            gap: 0 5px;

            b {
                font-size: 12px;
            }

            p {
                font-size: 11px;
            }
        }
    }

    .newsletter-input:after {
        font-size: 160px;
    }

    @media (max-width: 830px) {

        .newsletter-input:after {
            left: calc(50% + 120px);
            font-size: 150px;
        }
    }

    @media (max-width: 700px) {

        .newsletter-input:after {
            left: calc(50% + 80px);
        }
    }
}