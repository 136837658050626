// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1680px) and (min-width: 1280px) {
    .inner {
        width: 1170px;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////
    :root {
        --rowGap: 50px;
        --prodGap: 30px;
        --prodGapMainpage: var(--prodGap);
        --prodGapVertical: 30px;
    }

    main.aside {
        grid-gap: 32px;
    }

    .shop-header-container {
        gap: 0 30px;
        grid-template-columns: auto minmax(0, 1fr) minmax(0, 1fr);

    }

    .logo {
        grid-row: 1 / 4;
    }

    .header-top {
        display: contents;
    }

    .dark-mode-toggle {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        align-self: center;
    }

    .header-shipment {
        grid-column: 3 / 4;
        grid-row: 1 / 2;
        align-self: center;
        justify-self: end;
        margin-right: 0;
    }

    .mainmenu {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
        margin-bottom: 4px;
    }

    .header-contact-trigger {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        margin-bottom: 4px;
    }

    .header-bottom {
        grid-column: 2 / 4;
        grid-row: 3 / 4;
    }

    .blog-banners-section {
        margin-top: 0;
    }

    .footer-item.submenu
    ul {
        column-gap: 32px;
    }

    #breadcrumb {
        margin-bottom: -10px;
    }

    .navigation-wrapper {
        gap: 12px;
    }

    .dropdown-button {
        min-width: 250px;
    }

    .dropdown-picker[data-dropdown-type="pageSize"] .dropdown-button {
        min-width: 100px;
    }

    .detail-main-wrapper {
        grid-template-columns: 500px minmax(0, 1fr) 340px;
        grid-column-gap: 18px;
    }


    #detail-images {
        width: 100%;

        .product-images,
        .product-images .size-full {
            width: 500px;
            height: 500px;
        }

        .product-images-mini {
            width: 500px;
        }
    }

    .detail-info-wrapper {
        padding: 18px;
    }

    .detail-additional-infos,
    .detail-headline {
        margin-left: 0;
    }

    .detail-amount {
        width: 90px;

        button.minus {
            
            &:before {
                left: 11px; 
            }

            &:after {
                left: 17px;
            }

        }

        button.plus {

            &:before {
                right: 11px;
            }

            &:after {
                right: 17px;
            }
        }
    }

    .detail-main-info {
        margin-top: 64px;
    }

    .promo-social-links {

        i {
            margin-right: 6px;
            font-size: 20px;
        }

        a:before {
            font-size: 28px;
        }
    }

    .promo-bar-logo {
        margin-left: -76px;
        margin-right: 24px;
    }

    .icon-category-dropdown .icon-subcategory-list {
        gap: 12px 24px;
    }

    a.icon-category-trigger span {
        padding: 0 16px;

        &:before {
            inset: -5px 8px;
        }
    }
}