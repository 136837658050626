@media (max-width: 1680px) and (min-width: 1280px) {
  .inner {
    width: 1170px;
  }
  :root {
    --rowGap: 50px;
    --prodGap: 30px;
    --prodGapMainpage: var(--prodGap);
    --prodGapVertical: 30px;
  }
  main.aside {
    grid-gap: 32px;
  }
  .shop-header-container {
    gap: 0 30px;
    grid-template-columns: auto minmax(0, 1fr) minmax(0, 1fr);
  }
  .logo {
    grid-row: 1 / 4;
  }
  .header-top {
    display: contents;
  }
  .dark-mode-toggle {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    align-self: center;
  }
  .header-shipment {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    align-self: center;
    justify-self: end;
    margin-right: 0;
  }
  .mainmenu {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    margin-bottom: 4px;
  }
  .header-contact-trigger {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
    margin-bottom: 4px;
  }
  .header-bottom {
    grid-column: 2 / 4;
    grid-row: 3 / 4;
  }
  .blog-banners-section {
    margin-top: 0;
  }
  .footer-item.submenu ul {
    column-gap: 32px;
  }
  #breadcrumb {
    margin-bottom: -10px;
  }
  .navigation-wrapper {
    gap: 12px;
  }
  .dropdown-button {
    min-width: 250px;
  }
  .dropdown-picker[data-dropdown-type="pageSize"] .dropdown-button {
    min-width: 100px;
  }
  .detail-main-wrapper {
    grid-template-columns: 500px minmax(0, 1fr) 340px;
    grid-column-gap: 18px;
  }
  #detail-images {
    width: 100%;
  }
  #detail-images .product-images,
  #detail-images .product-images .size-full {
    width: 500px;
    height: 500px;
  }
  #detail-images .product-images-mini {
    width: 500px;
  }
  .detail-info-wrapper {
    padding: 18px;
  }
  .detail-additional-infos,
  .detail-headline {
    margin-left: 0;
  }
  .detail-amount {
    width: 90px;
  }
  .detail-amount button.minus:before {
    left: 11px;
  }
  .detail-amount button.minus:after {
    left: 17px;
  }
  .detail-amount button.plus:before {
    right: 11px;
  }
  .detail-amount button.plus:after {
    right: 17px;
  }
  .detail-main-info {
    margin-top: 64px;
  }
  .promo-social-links i {
    margin-right: 6px;
    font-size: 20px;
  }
  .promo-social-links a:before {
    font-size: 28px;
  }
  .promo-bar-logo {
    margin-left: -76px;
    margin-right: 24px;
  }
  .icon-category-dropdown .icon-subcategory-list {
    gap: 12px 24px;
  }
  a.icon-category-trigger span {
    padding: 0 16px;
  }
  a.icon-category-trigger span:before {
    inset: -5px 8px;
  }
}
@media (max-width: 1279px) {
  :root {
    --rowGap: 25px;
    --fontSize: 13px;
    --lineHeight: 1.4;
  }
  .detail-omnibus {
    align-self: flex-start;
    margin-top: 0;
    font-size: 11px;
  }
  .footer-bottom-bar {
    padding: 30px 0 100px;
  }
  #container {
    overflow: hidden;
  }
  .detail-omnibus {
    text-align: left;
  }
  main {
    padding-bottom: var(--rowGap) !important;
  }
  main.aside {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: var(--rowGap) 0;
  }
  .inner {
    width: 100%;
    padding: 0 10px;
  }
  * {
    -webkit-tap-highlight-color: rgba(189, 195, 199, 0.75);
    -webkit-text-size-adjust: none;
  }
  body.privacy-box-visible {
    overflow: hidden;
  }
  body.privacy-box-visible #container {
    filter: blur(5px);
    pointer-events: none;
  }
  body:has(.left-side-cookies) .privacy-box-trigger {
    bottom: 15px;
    width: 35px;
    height: 35px;
  }
  body:has(.left-side-cookies) .privacy-box-trigger.right-side-cookies {
    right: 15px;
  }
  body:has(.left-side-cookies) .privacy-box-trigger.left-side-cookies {
    left: 15px;
  }
  .privacy-box {
    bottom: auto;
    right: auto;
    left: 15px !important;
    right: 15px !important;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100vw;
    max-height: calc(100% - 40px);
  }
  input[type=text],
  input[type=password],
  input[type=email],
  select,
  textarea {
    font-size: 16px;
    padding: 0 14px;
  }
  textarea {
    padding: 10px 14px;
  }
  #facebook-box,
  .detail-loupe {
    display: none;
  }
  .headline {
    font-size: 20px !important;
    margin-bottom: 10px;
  }
  #content {
    width: 100%;
    clear: both;
  }
  .sidebar {
    width: auto;
    display: contents;
  }
  .sidebar .sidebar-tabs {
    display: none;
  }
  #search-engine {
    display: none;
  }
  .newsletter-bg {
    background-image: none !important;
  }
  .newsletter-input {
    width: 100%;
  }
  .footer-content {
    flex-direction: column;
    padding: 20px 0;
  }
  .footer-item {
    width: 100% !important;
  }
  body:has(.gs-filtr-container) .filter-trigger {
    display: flex;
  }
  .filter-trigger,
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button {
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    min-width: 0;
    font-size: 13px;
  }
  .filter-trigger:before,
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button:before {
    display: none;
  }
  .filter-trigger svg,
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button svg {
    position: static;
    transform: rotate(90deg);
    height: auto;
    width: 18px;
    filter: brightness(0);
    margin-right: 5px;
  }
  .filter-trigger:after {
    content: 'i sortowanie';
  }
  .dropdown-picker[data-dropdown-type="sort"] {
    display: none;
  }
  #filter {
    display: none;
  }
  .product-images-mini {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
  .product-images-mini .swiper-wrapper {
    transform: none !important;
    width: auto;
    justify-content: center;
  }
  .product-images-mini .swiper-slide {
    width: 10px;
    height: 10px;
    background-color: var(--borderColor);
    border-radius: 50%;
    margin: 0 5px;
  }
  .product-images-mini img {
    display: none !important;
  }
  .user-custom-content p {
    margin-bottom: 10px;
  }
  hr {
    margin: 20px 0;
  }
  .overlay-visible {
    overflow: hidden;
    height: 100dvh;
    width: 100dvw;
    position: fixed;
    top: 0;
  }
  main:not(.aside) #tree {
    display: block;
  }
  .tree-canvas {
    position: fixed;
    background: #000000 none repeat scroll 0% 0%;
    will-change: opacity;
    transition: opacity 300ms ease 0s;
    inset: 0px;
    z-index: 1001;
    pointer-events: none;
    opacity: 0;
  }
  #tree {
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(-100%, 0, 0);
    overflow: hidden;
  }
  :root {
    --rowGap: 25px;
    --prodGap: 20px;
    --prodGapMainpage: var(--prodGap);
    --prodGapVertical: 30px;
    --iconSize: 20px;
  }
  .top-bar {
    padding: 4px 0;
    font-size: 13px;
  }
  .top-bar .top-bar-close {
    margin-right: 4px;
  }
  .top-bar .top-bar-close:before {
    font-size: 17px;
  }
  #container {
    overflow: clip;
  }
  .shop-header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: var(--totalColor);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  }
  .tree-trigger,
  .search-engine-trigger {
    display: block;
  }
  .shop-header-container {
    gap: 0 30px;
    grid-template-columns: auto auto repeat(2, minmax(0, 1fr));
    align-items: center;
    grid-row-gap: 8px;
    padding: 6px 0 8px;
  }
  .logo {
    grid-row: 1 / 3;
    width: 100px;
  }
  #tree-top {
    display: none;
  }
  .header-top {
    display: contents;
  }
  .mainmenu {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    margin: 0;
    align-self: end;
  }
  .header-contact-trigger {
    grid-column: 4 / 5;
    grid-row: 1 / 2;
    align-self: end;
  }
  .dark-mode-toggle {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }
  .header-shipment {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }
  .header-bottom {
    grid-column: 4 / 5;
    grid-row: 2 / 3;
    justify-self: end;
  }
  .header-tools-wrapper {
    gap: 20px;
  }
  .search-engine-trigger,
  #wishlist-box,
  .log-button,
  #cart-box,
  .tree-trigger {
    padding-top: 27px;
  }
  .search-engine-trigger:before,
  #wishlist-box:before,
  .log-button:before,
  #cart-box:before,
  .tree-trigger:before {
    font-size: 24px;
  }
  .search-engine-trigger em,
  #wishlist-box em,
  .log-button em,
  #cart-box em,
  .tree-trigger em {
    font-size: 10px;
    font-weight: bold;
  }
  .tree-trigger .hamburger-icon {
    gap: 6px;
    height: 24px;
  }
  .tree-trigger .hamburger-icon span {
    height: 1px;
  }
  body[data-type="mainpage"] main.inner {
    padding-top: var(--rowGap);
  }
  .icon-category-section {
    padding-bottom: var(--rowGap);
    gap: 20px 12px;
  }
  .icon-category-dropdown {
    gap: 20px;
  }
  a.icon-category-trigger {
    gap: 10px;
  }
  a.icon-category-trigger span {
    padding: 0 6px;
    font-size: 12px;
  }
  a.icon-category-trigger.dropdownActive:after {
    bottom: -38px;
  }
  .mainpage-greeting-wrapper:has( > :nth-child(2)) figure {
    top: 132px;
  }
  .mainpage-greeting-wrapper .mainpage-greeting {
    padding: var(--rowGap) 0;
  }
  .greeting-img:only-child {
    max-width: 944px;
    width: 100% !important;
    padding: 0 !important;
  }
  .greeting-img:only-child img {
    width: 100% !important;
  }
  .blog-banners-section {
    margin-top: 0;
  }
  .blog-banners-section i {
    font-size: 12px;
    gap: 8px;
  }
  .promo-bar {
    height: 170px;
  }
  .promo-social-links i {
    margin-right: 6px;
    font-size: 20px;
  }
  .promo-social-links a:before {
    font-size: 24px;
  }
  .promo-bar-logo {
    width: 278px;
    margin-left: -60px;
    margin-right: 0;
  }
  .promo-banners-wrapper {
    justify-content: space-around;
  }
  #newsletter {
    padding: 32px 0;
  }
  .newsletter-header b {
    font-size: 20px;
  }
  .newsletter-input {
    max-width: 80%;
    --iconSize: 24px;
  }
  .footer-item.submenu ul {
    columns: 1;
  }
  .footer-item > strong {
    font-size: 18px;
  }
  .footer-item.contact .contact-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .footer-logo {
    margin-top: 10px;
    width: 144px !important;
  }
  .footer-bottom-bar {
    flex-direction: column;
    gap: 16px;
    padding: 16px 0 67px;
  }
  .footer-item.contact {
    max-width: none;
  }
  .sidebar {
    display: contents;
  }
  .sidebar > *:not(#tree, .tree-canvas) {
    display: none;
  }
  #breadcrumb {
    padding: 10px 0;
    margin-bottom: -10px;
  }
  .listing-wrapper {
    justify-content: flex-start;
    gap: var(--rowGap) 18px;
  }
  .listing-wrapper .headline {
    margin-bottom: 0;
    align-self: center;
  }
  .listing-wrapper:has(.category-headline) {
    margin-top: 0;
  }
  .pagination {
    margin-top: 0;
    gap: 24px;
  }
  .category-description {
    margin-top: 18px;
  }
  .category-headline {
    width: 100%;
  }
  .navigation-wrapper {
    margin-left: 0;
  }
  #pills-container:not(:empty) {
    margin-top: -12px;
    margin-bottom: 0 !important;
  }
  .product {
    gap: 10px;
  }
  .product:after {
    display: none !important;
  }
  .product-bottom-wrapper {
    flex-wrap: wrap;
    gap: 10px 4px;
  }
  .product-price {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 8px;
    align-items: flex-end;
    height: auto;
  }
  .product-price b {
    font-size: 14px;
  }
  .product-price del {
    font-size: 13px;
  }
  .product-wishlist {
    margin-left: 0;
  }
  .product .product-add {
    flex: 1;
    font-size: 14px;
    padding: 0;
  }
  .product .product-add:after {
    content: 'produkt';
    text-transform: uppercase;
    font-weight: 700;
    margin-left: -6px;
  }
  .product:has(.product-add.disabled) .product-wishlist {
    flex: 1;
    display: flex;
    gap: 14px;
  }
  .product:has(.product-add.disabled) .product-wishlist:after {
    content: 'Dodaj do ulubionych';
    text-transform: uppercase;
    font-weight: 700;
    margin-left: -6px;
    font-size: 14px;
  }
  .detail-main-wrapper {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
  .detail-main-wrapper .detail-headline {
    margin: 0;
    padding: 0;
    text-align: center;
  }
  #detail-images {
    align-items: center;
    flex-direction: column;
    gap: 20px;
    height: auto;
    left: auto;
    margin: 0 auto;
    position: relative;
    top: auto;
    width: 100%;
  }
  #detail-images .swiper-button-prev {
    left: 10px;
  }
  #detail-images .swiper-button-next {
    right: 10px;
  }
  .product-images-mini {
    height: auto;
    margin-top: 10px;
    width: 100%;
  }
  .product-images-mini .size-custom {
    padding: 0 !important;
  }
  .detail-info-wrapper {
    margin-top: 0;
    padding: 16px;
  }
  .detail-badges {
    padding-bottom: 10px;
    justify-content: flex-start;
  }
  .detail-pricing {
    margin-bottom: 8px;
    justify-content: flex-start;
  }
  .detail-adding {
    margin-top: 4px;
    margin-bottom: 20px;
  }
  .detail-options dl {
    padding-bottom: 16px;
  }
  .detail-options dl dt {
    font-size: 14px;
  }
  .detail-accordion-trigger {
    font-size: 16px;
  }
  .side-xsell-wrapper {
    display: none;
  }
  .detail-accordion {
    padding: 20px 0 16px;
  }
  #detail-info:has(.detail-accordion) .detail-shipping-cost {
    margin-top: 16px;
  }
  .detail-additional-infos {
    margin-left: 0;
  }
  .detail-stars-wrapper {
    padding: 0 !important;
    margin: 0 !important;
  }
  .detail-additional-infos > * {
    padding-top: 16px;
    margin-top: 16px;
  }
  .detail-buttons {
    font-size: 14px;
  }
  .detail-buttons > * {
    padding-left: 32px;
  }
  .detail-main-info {
    margin-top: 5px;
    gap: var(--rowGap);
  }
  #detail-traits .trait {
    gap: 24px;
  }
  #detail-traits .trait b {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
    flex: 1;
  }
  #detail-traits .trait .trait-desc {
    width: 50%;
    flex: 1;
  }
  .detail-main-info .headline {
    margin-bottom: 10px;
  }
  #detail-traits h2 {
    padding-bottom: 10px;
  }
  .detail-comments-header {
    margin-bottom: 10px;
  }
  .detail-comments-header .headline {
    margin-bottom: 0;
  }
  .bought-with-wrapper .headline {
    margin-bottom: 18px;
  }
  .comment > cite {
    padding: 20px;
    margin-bottom: 12px;
  }
  .comment-response {
    margin-block: 12px 6px;
    padding: 20px;
  }
  .under-comment .added-date {
    padding-left: 17px;
    margin-left: 0;
  }
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    .header-tools-wrapper > * {
      margin-left: 10px;
    }
    .hamburger-icon {
      display: grid;
      grid-auto-rows: max-content;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 6px);
      gap: 0;
      justify-items: center;
      align-items: center;
    }
    .tree-canvas {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
@media (max-width: 1100px) {
  .shop-header-container {
    gap: 8px 16px;
  }
  .mainmenu {
    gap: 16px;
  }
  .mainmenu a {
    font-size: 14px;
  }
  :root {
    --categorySectionColumns: 6;
  }
  .product .product-wishlist,
  .product .product-add {
    font-size: 13px;
    gap: 6px;
  }
  .product .product-wishlist:before,
  .product .product-add:before {
    font-size: 16px;
  }
  .product .product-add:after {
    margin-left: -2px;
  }
  .product:has(.product-add.disabled) .product-wishlist:before {
    display: none;
  }
  .product:has(.product-add.disabled) .product-wishlist:after {
    margin-left: 0;
    font-size: 12px;
    white-space: nowrap;
  }
  .pagination {
    gap: 12px;
  }
  .mainpage-greeting-wrapper {
    flex-direction: column;
    align-items: center;
  }
  .mainpage-greeting-wrapper figure {
    width: 100% !important;
    max-width: 944px !important;
    margin: 0 auto;
    padding: var(--rowGap);
    padding-bottom: 0;
  }
  .mainpage-greeting-wrapper figure:before,
  .mainpage-greeting-wrapper figure:after {
    left: calc(var(--rowGap) - 2px) !important;
  }
  .mainpage-greeting-wrapper figure:before {
    top: calc(var(--rowGap)) !important;
  }
  .mainpage-greeting-wrapper .mainpage-greeting {
    margin-right: 0 !important;
    text-align: center;
    padding-bottom: 0;
    max-width: 90%;
  }
  .blog-banners-wrapper {
    grid-template-columns: minmax(0, 1fr);
    gap: 18px;
  }
  .blog-banner {
    grid-template-rows: auto auto auto;
  }
  .blog-banner figure {
    grid-row: span 4;
  }
  .promo-bar {
    height: 100px;
  }
  .promo-social-links {
    gap: 8px;
  }
  .promo-social-links i {
    display: none;
  }
  .promo-social-links a:before {
    font-size: 20px;
  }
  .promo-bar-logo {
    width: 160px;
    margin-left: -40px;
    margin-right: 8px;
  }
  .under-comment {
    padding: 0;
    font-size: 12px;
    gap: 13px;
  }
  .under-comment .added-date {
    padding-left: 13px;
  }
  .under-comment .purchase-confirmed {
    gap: 8px;
  }
  .under-comment .purchase-confirmed:before {
    font-size: 18px;
  }
  .comment-response {
    margin-left: 18px;
  }
  .promo-banners-wrapper {
    gap: 10px;
  }
  .promo-banners-wrapper .promo-banner {
    grid-template-columns: 30px minmax(0, 1fr);
    gap: 0 5px;
  }
  .promo-banners-wrapper .promo-banner b {
    font-size: 12px;
  }
  .promo-banners-wrapper .promo-banner p {
    font-size: 11px;
  }
  .newsletter-input:after {
    font-size: 160px;
  }
}
@media (max-width: 1100px) and (max-width: 910px) {
  .header-shipment,
  .mainmenu {
    display: none;
  }
  .shop-header-container {
    grid-template-columns: auto auto minmax(0, 1fr);
  }
  .header-contact-trigger {
    grid-column: 3 / 4;
  }
  .dark-mode-toggle {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    align-self: end;
  }
  .header-bottom {
    grid-column: 2 / 4;
  }
}
@media (max-width: 1100px) and (max-width: 870px) {
  :root {
    --categorySectionColumns: 4;
  }
  .icon-category-dropdown .icon-subcategory-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (max-width: 1100px) and (max-width: 830px) {
  .newsletter-input:after {
    left: calc(50% + 120px);
    font-size: 150px;
  }
}
@media (max-width: 1100px) and (max-width: 700px) {
  .newsletter-input:after {
    left: calc(50% + 80px);
  }
}
@media (max-width: 600px) {
  .detail-share-links-wrapper span {
    width: 100%;
  }
  .stars-rating.average-rating {
    top: -2px;
  }
  .gs-tabs nav {
    flex-direction: column;
  }
  .gs-tabs nav button {
    width: 100%;
  }
  .user-custom-content iframe {
    max-width: 100%;
  }
  .filter-trigger {
    padding: 0 20px;
    justify-content: flex-start;
    gap: 4px;
    width: fit-content;
  }
  .dropdown-picker[data-dropdown-type="pageSize"] {
    flex-grow: 1;
  }
  .dropdown-picker[data-dropdown-type="pageSize"] .dropdown-button {
    width: 100%;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]):before {
    left: 13px;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button {
    padding: 0 36px 0 20px;
    min-width: 0;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button:after {
    width: 36px;
    right: 1px;
  }
  .privacy-box {
    padding: 10px;
    gap: 10px;
  }
  .privacy-box__header {
    gap: 10px;
  }
  .privacy-box__header img {
    height: 20px;
  }
  .privacy-box__header strong {
    font-size: 14px;
  }
  .privacy-box__actions {
    flex-wrap: wrap;
  }
  .privacy-box__actions button {
    min-height: 35px;
  }
  .privacy-box__actions button.privacy-accept-all {
    width: 100%;
    order: -1;
    flex: 0 0 auto;
    min-height: 45px;
  }
  .pagination {
    gap: 4px;
  }
  .pagination a {
    width: 30px;
    height: 30px;
  }
  #breadcrumb {
    display: flex;
    margin-left: -10px;
    width: calc(100% + 20px);
    justify-content: flex-start;
    position: relative;
  }
  #breadcrumb .breadcrumb-end {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 20px;
    pointer-events: none;
  }
  #breadcrumb .breadcrumb-end:nth-of-type(1) {
    left: -7px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb .breadcrumb-end:nth-of-type(2) {
    right: -7px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb ul {
    flex-wrap: nowrap;
    overflow-y: auto;
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding-bottom: 5px;
  }
  #breadcrumb li {
    white-space: nowrap;
  }
  #breadcrumb li:last-of-type {
    padding-right: 10px;
  }
  #breadcrumb li:first-of-type {
    padding-left: 10px;
  }
  #breadcrumb li:nth-of-type(1) {
    order: 10;
  }
  #breadcrumb li:nth-of-type(2) {
    order: 9;
  }
  #breadcrumb li:nth-of-type(3) {
    order: 8;
  }
  #breadcrumb li:nth-of-type(4) {
    order: 7;
  }
  #breadcrumb li:nth-of-type(5) {
    order: 6;
  }
  #gsGalleryContainer > a {
    width: 100%;
  }
  .navigation-wrapper {
    padding-top: 0;
    border-top: 0;
  }
  .availability-modal-wrapper .wrapper {
    justify-content: center;
    gap: 10px;
  }
  .availability-modal-wrapper .wrapper > p {
    text-align: center;
  }
  .availability-modal-wrapper .wrapper > .availability-product {
    justify-content: center;
  }
  .availability-modal-wrapper .availability-product {
    gap: 10px;
    padding: 10px 0;
  }
  .availability-modal-wrapper .input-wrapper {
    flex-wrap: wrap;
  }
  .availability-modal-wrapper .input-wrapper > * {
    width: 100%;
  }
  .product-images {
    width: 100%;
  }
  .product-images .size-full {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
  .detail-amount {
    width: 100%;
    margin: 0 0 10px;
  }
  .pills-group:before,
  .remove-all-filters {
    font-size: 11px;
  }
  .single-pill {
    font-size: 11px;
    padding: 2px 5px;
  }
  #pills-container:not(:empty) {
    margin-bottom: 20px;
  }
  .listing-wrapper {
    gap: var(--rowGap) 6px;
  }
  .header-tools-wrapper {
    gap: 16px;
  }
  .mainmenu {
    gap: 8px;
    grid-column: 1 / 5;
  }
  .mainmenu a {
    font-size: 12px;
  }
  .mainpage-greeting {
    max-width: none !important;
  }
  .blog-banner {
    gap: 12px;
  }
  .newsletter-input {
    max-width: none;
  }
  .promo-banners-wrapper {
    display: none;
  }
  .promo-social-links i {
    display: block;
    font-size: 16px;
  }
  .promo-social-links a.instagram-link {
    margin-left: 2px;
  }
  .promo-bar-logo {
    margin-right: 78px;
  }
  #detail-traits .trait {
    flex-direction: column;
    gap: 6px;
    padding: 12px 0;
  }
  #detail-traits .trait b {
    width: 100%;
    justify-content: flex-start;
  }
  #detail-traits .trait .trait-desc {
    width: 100%;
  }
  .detail-comments-header {
    flex-wrap: wrap;
  }
  .newsletter-input:after {
    left: calc(50% + 40px);
  }
  .under-comment {
    display: grid;
    grid-auto-rows: max-content;
    grid-template-columns: auto minmax(0, 1fr);
    padding: 0;
  }
  .under-comment .author {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
  .under-comment .stars-rating {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    margin-left: auto;
  }
  .under-comment .added-date {
    margin: 0;
    padding: 0;
    border-left: none;
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }
  .under-comment .purchase-confirmed {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }
  .icon-category-dropdown .icon-subcategory-list {
    gap: 10px;
  }
  .icon-category-dropdown .icon-subcategory-list a {
    gap: 8px;
  }
  .icon-category-dropdown .icon-subcategory-all {
    font-size: 12px;
  }
  .icon-category-dropdown .icon-subcategory-all span {
    gap: 8px;
  }
  .icon-category-dropdown .icon-subcategory-all span:after {
    transform: translateY(1px);
  }
  :root {
    --categorySectionColumns: 3;
  }
  .icon-category-dropdown .icon-subcategory-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 600px) and (max-width: 460px) {
  :root {
    --categorySectionColumns: 2;
  }
  .blog-banners-wrapper {
    display: grid;
    grid-auto-rows: max-content;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px 10px;
  }
  .blog-banner {
    display: grid;
    grid-auto-rows: max-content;
    grid-template-rows: auto auto 1fr auto;
    grid-template-columns: none;
    width: 100%;
  }
  .blog-banner figure {
    grid-row: 1 / 2;
    width: 100%;
  }
  .listing-wrapper:has(.filter-trigger) .navigation-wrapper,
  .listing-wrapper:has(.filter-trigger) .filter-trigger {
    width: 100%;
  }
  .listing-wrapper:has(.filter-trigger) .filter-trigger {
    margin-top: -12px;
  }
  .listing-wrapper:has(.filter-trigger) .filter-trigger button {
    white-space: nowrap;
  }
  .listing-wrapper:has(.filter-trigger) .filter-trigger svg {
    flex-shrink: 0;
  }
}
@media (max-width: 600px) and (max-width: 450px) {
  .shop-header-container {
    grid-template-columns: auto minmax(0, 1fr);
    grid-template-rows: auto auto auto;
    position: relative;
    padding: 4px 0 6px;
  }
  .logo {
    height: 72px;
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }
  .dark-mode-toggle {
    justify-self: end;
    transform: translateY(10px);
  }
  .shop-header-container:has(.header-contact-trigger) .dark-mode-toggle {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    align-self: start;
    transform: none;
  }
  .header-contact-trigger {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    align-self: center;
    margin-top: 6px;
  }
  .header-bottom {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    width: 100%;
  }
  .header-bottom .header-tools-wrapper {
    justify-content: space-evenly;
    margin-right: 0;
    width: 100%;
  }
  .promo-bar-logo {
    margin-right: 3px;
  }
  .top-bar span {
    font-size: 11px;
    padding: 0 24px 0 0;
  }
}
@media (max-width: 600px) and (max-width: 400px) {
  .icon-category-dropdown .icon-subcategory-list {
    grid-template-columns: minmax(0, 1fr);
  }
  .promo-social-links i {
    font-size: 13px;
  }
}
@media (max-width: 600px) and (max-width: 345px) {
  .top-bar span {
    font-size: 10px;
  }
}
